// 全局通用增删查改模板 作者：PeterLee 日期：2021年9月10日
import axios from '@/config/httpConfig'
import baseURL from '@/config/baseUrl'
// 为控制浏览器对get请求缓存问题,添加时间来区别
let refreshSuffix = "?_="+(new Date().getTime())
/****************** 基础增删查改 *****************/
// 获取信息（分页）
export function getPage(modelName,tableName,data) {
    return axios.post('/'+modelName+'/'+tableName+'/page',data)
}

// 获取列表信息
export function getList(modelName,tableName) {
    return axios.get("/"+modelName+"/"+tableName+"/list")
}

// 根据id获取信息
export function getInfoById(modelName,tableName,id) {
    return axios.get("/"+modelName+"/"+tableName+"/"+id)
}

// 添加信息
export function addData(modelName,tableName,data) {
    return axios.post("/"+modelName+"/"+tableName,data)
}

// 修改信息
export function updateData(modelName,tableName,data) {
    return axios.put("/"+modelName+"/"+tableName,data)
}

// 批量删除
export function removeData(modelName,tableName,data) {
    return axios.delete("/"+modelName+"/"+tableName,data)
}

// 单个删除
export function removeById(modelName,tableName,id) {
    return axios.delete("/"+modelName+"/"+tableName+"/"+id)
}

// 批量修改状态
export function updateState(modelName,tableName,param) {
	 return axios.put("/"+modelName+"/"+tableName+"/state",param)
}
/****************** 文件请求 *****************/
// 图片下载
export function imgDownload(url,fileName) {
    //下载图片地址和图片名
    var image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据'
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window
        }); // 创建一个单击事件
        a.download = fileName || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = url;
}
// 文件下载请求,传入"文件夹/文件名"
export function downloadFile(path) {
    window.open(baseURL+"system/file/download/"+path,'_self');
}
export function downloadSystemFile(fileName) {
    let index = baseURL.indexOf("/api/");
    let hostUrl = baseURL.slice(0, index + 1);
    window.open(hostUrl+fileName,'_self');
}
// 文件导出请求
export function exportFile(path) {
    window.open(baseURL + path,'_self');
}
// 导出excel
export function exportExcel(moduleName,tableName,fileName,ids) {
    return axios.download("/"+moduleName+"/"+tableName+"/export",fileName+".xlsx",ids);
}
// 全量导出excel
export function exportExcelAll(moduleName,tableName,fileName) {
    return axios.getDownload("/"+moduleName+"/"+tableName+"/export/all",fileName+".xlsx");
}
// excel导入模板
export function importExcelTemplate(moduleName,tableName,fileName) {
    return axios.getDownload("/"+moduleName+"/"+tableName+"/import/template",fileName+".xlsx")
}
// 导入excel
export function importExcel(moduleName,tableName,formData) {
    return axios.importExcel("/"+moduleName+"/"+tableName+"/import",formData)
}
/****************** 通用请求 *****************/
// 根据用户id获取name
export function getNameById(modelName,tableName,id) {
    return axios.get("/"+modelName+"/"+tableName+"/name/"+id);
}
// 根据一个id查询list(多用于关联表)
export function getListById(modelName,tableName,id) {
    return axios.get("/"+modelName+"/"+tableName+"/list/"+id);
}
// 批量关联object(object为String)
export function batchRelation(modelName,tableName,object,data) {
    return axios.put("/"+modelName+"/"+tableName+"/batch"+object,data);
}
// 枚举字典获取
export function enumDictItems(moduleName,code) {
    return axios.get(moduleName+"/enum/"+code)
}
// 获取扫描权限码
export function getPermissionScan(moduleName) {
    return axios.get("/"+moduleName+"/permission/list")
}
// 获取文件上传的签名
export function getFileUploadSign() {
    // 避免签名缓存
    return axios.get("/oss/file/sign"+ refreshSuffix)
}
// 获取文件预览地址
export async function getFilePreviewUrl() {
    return await axios.get("/oss/file/previewUrl"+ refreshSuffix)
}