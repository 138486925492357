/**
 * 数据表字段表状态管理（vuex的state可以单独出来，便于管理，同样mutations和actions都可以提取出来）
 *
 * @author PeterLee
 * @since 2022-07-18
 */
const state = {
   // 表单校验规则
   rules: {
      // 表id
     //  tableId:[{
     //     required: true,
     //     message: '请输入表id',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 字段名
     //  name:[{
     //     required: true,
     //     message: '请输入字段名',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 字段排序
     //  ordinalPosition:[{
     //     required: true,
     //     message: '请输入字段排序',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 默认值
     //  fieldDefault:[{
     //     required: false,
     //     message: '请输入默认值',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 是否为空
     //  isNullable:[{
     //     required: true,
     //     message: '请输入是否为空',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 数据类型
     //  dataType:[{
     //     required: true,
     //     message: '请输入数据类型',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 字符最大长度
     //  characterMaximumLength:[{
     //     required: false,
     //     message: '请输入字符最大长度',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 数值精度（最大位数）
     //  numericPrecision:[{
     //     required: false,
     //     message: '请输入数值精度（最大位数）',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 小数精度
     //  numericScale:[{
     //     required: false,
     //     message: '请输入小数精度',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 字段类型
     //  fieldType:[{
     //     required: true,
     //     message: '请输入字段类型',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 键类型
     //  keyType:[{
     //     required: false,
     //     message: '请输入键类型',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 额外说明
     //  extra:[{
     //     required: false,
     //     message: '请输入额外说明',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 注释
     //  fieldComment:[{
     //     required: false,
     //     message: '请输入注释',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
      // 表单区组件
      formWidget:[{
         required: false,
         message: '请输入表单区组件',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      // 搜索区组件
      searchWidget:[{
         required: false,
         message: '请输入搜索区组件',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      // 表格区组件
      tableWidget:[{
         required: false,
         message: '请输入表格区组件',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      //是否枚举字段
      isEnum:[{
         required: true,
         message: '请选择是否枚举字段',
         trigger: 'change'
      }
     ],
      //枚举code
      enumCode:[{
         required: false,
         message: '请输入枚举code',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 100,
            message: '长度在 1 到 100 个字符',
            trigger: 'blur'
        }
     ],
      //是否列表字段
      isList:[{
         required: true,
         message: '请选择是否列表字段',
         trigger: 'change'
      }
     ],
      //列表模块code
      listModuleCode:[{
         required: false,
         message: '请输入列表模块code',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      //列表表code
      listTableCode:[{
         required: false,
         message: '请输入列表表code',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      //是否树型
      isTree:[{
         required: true,
         message: '请选择是否树型',
         trigger: 'change'
      }
     ],
      //是否树外置
      isTreeOut:[{
          required: false,
          message: '请选择是否树外置',
          trigger: 'change'
      }
     ],
      // 状态
      state:[{
         required: true,
         message: '请输入状态',
         trigger: 'change'
      }
     ],
   },
   // 列信息
   columnInfo: {
       // 表id
       tableId:{
            visible:false,
            label:"表id"
       },
       // 字段名
       name:{
            visible:true,
            label:"字段名"
       },
       // 字段排序
       ordinalPosition:{
            visible:true,
            label:"排序"
       },
       // 默认值
       fieldDefault:{
            visible:true,
            label:"默认值"
       },
       // 是否为空
       isNullable:{
            visible:true,
            label:"是否为空"
       },
       // 数据类型
       dataType:{
            visible:false,
            label:"数据类型"
       },
       // 字符最大长度
       characterMaximumLength:{
            visible:false,
            label:"字符最大长度"
       },
       // 数值精度（最大位数）
       numericPrecision:{
            visible:false,
            label:"数值精度（最大位数）"
       },
       // 小数精度
       numericScale:{
            visible:false,
            label:"小数精度"
       },
       // 字段类型
       fieldType:{
            visible:false,
            label:"字段类型"
       },
       // 键类型
       keyType:{
            visible:false,
            label:"键类型"
       },
       // 额外说明
       extra:{
            visible:false,
            label:"额外说明"
       },
       // 注释
       fieldComment:{
            visible:true,
            label:"注释"
       },
       // 表单区组件
       formWidget:{
            visible:false,
            label:"表单区组件"
       },
       // 搜索区组件
       searchWidget:{
            visible:false,
            label:"搜索区组件"
       },
       // 表格区组件
       tableWidget:{
            visible:false,
            label:"表格区组件"
       },
       //是否枚举字段
       isEnum:{
            visible:true,
            label:"是否枚举字段"
       },
       //枚举code
       enumCode:{
            visible:true,
            label:"枚举code"
       },
       //是否列表字段
       isList:{
            visible:true,
            label:"是否列表字段"
       },
       //列表模块code
       listModuleCode:{
            visible:true,
            label:"列表模块code"
       },
       //列表表code
       listTableCode:{
            visible:true,
            label:"列表表code"
       },
       //是否树型
       isTree:{
            visible:true,
            label:"是否树型"
       },
       //是否树外置
       isTreeOut:{
            visible:true,
            label:"是否树外置"
       },
       //状态
       state:{
            visible:true,
            label:"状态"
       },
   },
   // 表格数据（查询结果）
   tableData: [],
   // 共有数据条数（分页控件）
   totalCount: 0,
   // 选项列表（列表下拉框选择）
   options: {
       // 表单区组件列表
       formWidgetOptions: [],
       // 表格区组件列表
       tableWidgetOptions: [],
       // 搜索区组件列表
       searchWidgetOptions: []
   },
   // 查询参数
   queryParams: {
      // 当前页数（分页控件）
      currPage: 1,
      // 每页条数（分页控件）
      limit: 10,
      // 是否升序
      ascOrder: false,
      // 排序字段
      orderField: "",
      // 此处为可能需要修改部分，请不要忽略
      param: {
         // 表id
         tableId: undefined,
         // 字段名
         name: undefined,
         // 字段排序
         ordinalPosition: undefined,
         // 默认值
         fieldDefault: undefined,
         // 是否为空
         isNullable: undefined,
         // 数据类型
         dataType: undefined,
         // 字符最大长度
         characterMaximumLength: undefined,
         // 数值精度（最大位数）
         numericPrecision: undefined,
         // 小数精度
         numericScale: undefined,
         // 字段类型
         fieldType: undefined,
         // 键类型
         keyType: undefined,
         // 额外说明
         extra: undefined,
         // 注释
         fieldComment: undefined,
         // 表单区组件
         formWidget: undefined,
         // 搜索区组件
         searchWidget: undefined,
         // 表格区组件
         tableWidget: undefined,
         //是否枚举字段
         isEnum: undefined,
         //枚举code
         enumCode: undefined,
         //是否列表字段
         isList: undefined,
         //列表模块code
         listModuleCode: undefined,
         //列表表code
         listTableCode: undefined,
         //是否树型
         isTree: undefined,
         //是否树外置
          isTreeOut: undefined,
         //状态
         state: undefined,
      }
   },
   // 时间范围
   dateRange: {},
   // 表单组件信息
   formInfo: {
      // 表单组件弹出框显示控制
      dialogVisible: false,
      // 是否增加操作（非增加操作会隐藏部分操作）
      isAdd: false,
      // 编辑回显数据
      editData: {},
   },
   // 更多按钮信息
   moreButtonProps:[
      //{command:'test',label:'示例按钮',permissionCode:'itil:event'}
   ]
}
export default state