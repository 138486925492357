import axios from 'axios'
import store from '@/store/index'
import baseURL from './baseUrl'
import { Message } from 'element-ui'
import Qs from 'qs';
const http = {}

const instance = axios.create({
    timeout: 10000,
    baseURL,
    validateStatus(status) {
        switch (status) {
            case 400:
                Message.error('请求出错(400)')
                break
            case 403:
                Message.warning({
                    message: '拒绝访问(403)'
                })
                break
            case 404:
                Message.warning({
                    message: '请求错误,未找到该资源(404)'
                })
                break
            case 500:
                Message.warning({
                    message: '服务端错误(500)'
                })
                break
        }
        return status >= 200 && status < 300
    }
});

// 添加请求拦截器
instance.interceptors.request.use(
    function(config) {
        // 请求头添加token
        if (store.state.user.UserToken) {
            config.headers['satoken'] = `${store.state.user.UserToken}`
        }
        return config
    },
    function(error) {
        return Promise.reject(error)
    }
)

// 响应拦截器即异常处理
instance.interceptors.response.use(
    response => {
		switch (response.data.code) {
		case 301001:
            setTimeout(() => {
                //退出
                store.dispatch('user/fedLogout')
            },2000);
		    break
		}
        return response.data
    },
    err => {
        if (err && err.response) {
			// Message.error({
			//     message: err.message
			// })
        } else {
            err.message = '连接服务器失败'
        }
        return Promise.reject(err.response)
    }
)

/**
 * get方法，对应get请求
 * @param url
 * @param params
 */
http.get = function(url, params = {}) {
    return new Promise((resolve, reject) => {
	instance.get(url, {params:params})
            .then(response => {
                if (response.code === 200) {
                    resolve(response)
                } else {
                    Message.error({
                        message: response.message
                    })
                    reject(response)
                }
            })
            .catch(e => {
                reject(e)
            })
    })
}

/**
 * post方法，对应post请求
 * @param url
 * @param data
 * @param config
 */
http.post = function(url, data,config = {}) {
    return new Promise((resolve, reject) => {
        instance
            .post(url, data, config)
            .then(response => {
                if (response.code === 200) {
                    resolve(response)
                } else {
                    Message.error({
                        message: response.message
                    })
                    reject(response)
                }
            })
            .catch(e => {
                reject(e)
            })
    })
}
/**
 * 导入excel方法
 * @param url
 * @param data
 * @returns {Promise<unknown>}
 */
http.importExcel = function (url,data){
    return new Promise((resolve, reject) => {
        instance
            .post(url, data, {
                headers: {
                    "Content-type": "multipart/form-data"
                }
            })
            .then(response => {
                if (response.code === 200) {
                    resolve(response)
                } else {
                    Message.error({
                        message: response.message
                    })
                    reject(response)
                }
            })
            .catch(e => {
                reject(e)
            })
    })
}

/**
 * download方法，对应post请求
 * @param url
 * @param fileName
 * @param data
 * @returns {Promise<unknown>}
 */
http.download = function (url , fileName, data) {
    return new Promise((resolve)=>{
        instance.post(url, data, {responseType: 'blob'}).then(res => {
            let blob = new Blob([res], {
                type: "application/vnd.ms-excel;charset=utf-8",
            });
            // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
            let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
            // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
            let link = document.createElement("a");
            link.href = url;
            link.download = fileName;
            link.click();
            // 5.释放这个临时的对象url
            window.URL.revokeObjectURL(url);
            resolve(0)
        }).catch(error => {
            Message.error({
                message: error.message
            })
            reject(error)
        })
    })
}

/**
 * download方法，对应get请求
 * @param url
 * @param fileName
 * @returns {Promise<unknown>}
 */
http.getDownload = function (url,fileName) {
    return new Promise((resolve) => {
        instance.get(url,{responseType: 'blob'})
         .then( res => {
             let blob = new Blob([res], {
                 type: "application/vnd.ms-excel;charset=utf-8",
             });
             // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
             let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
             // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
             let link = document.createElement("a");
             link.href = url;
             link.download = fileName;
             link.click();
             // 5.释放这个临时的对象url
             window.URL.revokeObjectURL(url);
             resolve(0)
        })
        .catch( e => {
            Message.error({
                message: e.message
            })
            reject(e)
        })
    })
}

/**
 * post方法，参数序列化请求
 * @param url
 * @param params
 */
http.postQs = function(url, params) {
    return new Promise((resolve, reject) => {
        instance
            .post(url, Qs.stringify(params), null)
            .then(response => {
                if (response.code === 200) {
                    resolve(response)
                } else {
                    Message.error({
                        message: response.message
                    })
                    reject(response)
                }
            })
            .catch(e => {
                reject(e)
            })
    })
}


/**
 * put方法，对应put请求
 * @param url
 * @param data
 */
http.put = function(url,data= {}){
	return new Promise((resolve, reject) => {
	    instance
	        .put(url, data, null)
	        .then(response => {
	            if (response.code === 200) {
	                resolve(response)
	            } else {
	                Message.error({
	                    message: response.message
	                })
	                reject(response)
	            }
	        })
	        .catch(e => {
                reject(e)
	        })
	})
}

/**
 * delete方法，对应delete请求
 * @param url
 * @param data
 */
http.delete = function(url,data= {}){
	return new Promise((resolve, reject) => {
	    instance
	        .delete(url,{data:data})
	        .then(response => {
	            if (response.code === 200) {
	                resolve(response)
	            } else {
	                Message.error({
	                    message: response.message
	                })
	                reject(response)
	            }
	        })
	        .catch(e => {
                reject(e)
	        })
	})
}

/**
 * delete方法，参数序列化请求
 * @param url
 * @param params
 */
http.deleteUrl = function(url,params){
	return new Promise((resolve, reject) => {
	    instance
	        .delete(url,{params:params})
	        .then(response => {
	            if (response.code === 200) {
	                resolve(response)
	            } else {
	                Message.error({
	                    message: response.message
	                })
	                reject(response)
	            }
	        })
	        .catch(e => {
                reject(e)
	        })
	})
}

export default http
